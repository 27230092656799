.layout-breadcrumb {
    margin-bottom: 2rem;

    ol {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style: none;
        gap: 1rem;
        flex-wrap: wrap;
        color: var(--text-color-secondary);
        
        a {
            color: var(--text-color-secondary);
        }
    }
}