/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

/* Layout */
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Quill Text Editor for p-editor */
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";
@import "assets/demo/styles/code.scss";
/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";

.hov-pointer {
  cursor: pointer;
}

.bg-table-info {
  background-color: #5f7adb !important;
  color: #fff !important;

}

.pre {
  white-space: pre-wrap !important;

}

.img-dropdown {
  width: 20px;
}

.d {
  z-index: 9999 !important;
}
.fc .fc-toolbar-title {
  font-size: 1.5em;
  margin: 0px;
}
/**

.fc .fc-toolbar-title {
  font-size: 1.75em !important;
  margin: 0px !important;
  display: none !important;
}



.custom-table-container {
  z-index: 1 !important;
  overflow-x: auto;
}
*/

.custom-table {
  /*  width: 50% !important; Puedes ajustar esto según tus necesidades */
  white-space: nowrap;
}

.label-img {
  text-align: center;
  padding: 3px;
  margin-right: 20px;
  transition: background-color 1s, color 1s;
  -webkit-transition: background-color 1s, color 1s;
  -moz-transition: background-color 1s, color 1s;
  -ms-transition: background-color 1s, color 1s;
  -o-transition: background-color 1s, color 1s;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

/*
 .label-img:hover{
  background-color: #3C5898;
  color: #fff;
  text-align: center;
  padding: 3px;
  margin-right: 20px;

}
*/

.contenedor-imagen {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: 400ms;
}

.contenedor-imagen>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contenedor-imagen:hover>img {
  filter: grayscale(100%) blur(2px);
  /* Convertimos la imagen a escala de grises y aplicamos un pequeño desenfoque */
}

.leyenda {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  font-weight: 700;
  font-size: 10px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.contenedor-imagen:hover .leyenda {
  opacity: 1;

}

.contenedor-imagen:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.5);
}

.border-left {
  border-left: 4px solid var(--primary-500);
  border-bottom: 4px solid var(--primary-500);
}

.borde-shadow {
  height: 150px;
  border-radius: 10px;
  /* Ajusta según sea necesario */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Ajusta según sea necesario */
  padding: 20px;
  /* Ajusta según sea necesario */
  background-color: #ffffff;
  /* Ajusta según sea necesario */
}

canvas {
  background-color: white;

  border-radius: 7px;
  box-shadow:
    0 0px 3.3px rgba(0, 0, 0, 0.017),
    0 0px 7.9px rgba(0, 0, 0, 0.024),
    0 0px 14.9px rgba(0, 0, 0, 0.03),
    0 0px 26.6px rgba(0, 0, 0, 0.036),
    0 0px 49.7px rgba(0, 0, 0, 0.043),
    0 0px 119px rgba(0, 0, 0, 0.06);


  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

.p-breadcrumb {
  background: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
  border: none !important;
  margin-bottom: 1rem !important;
}

.p-breadcrumb .p-breadcrumb-list {
  margin: 0 !important;
}

.p-breadcrumb .p-breadcrumb-list .p-breadcrumb-item a {
  color: #333 !important;
  text-decoration: none !important;
}

.p-breadcrumb .p-breadcrumb-list .p-breadcrumb-item a:hover {
  color: #007bff !important;
}

.p-calendar-w-btn {
  width: 100% !important;
}

.table-text {
  white-space: pre-wrap;
}
