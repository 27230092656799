* {
    box-sizing: border-box;
}

html {
    height: 100%;
    font-size: $scale;
}

body {
    font-weight: 400;
    padding: 0;
    margin: 0;
    min-height: 100%;
    background: var(--surface-ground);
    color: var(--text-color);
    font-family: var(--font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    a {
        text-decoration: none;
    }
}

.layout-container {
    background: var(--v-body-bg);
    min-height: 100vh;
}
